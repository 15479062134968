import * as React from "react"

import Layout from "../components/Layout"
import {
  BreadLink,
  BreadTypography,
  CategoryCard,
  ConsumableCard,
  getCategoryList,
  PageHeader,
  ProductCard,
} from "../components/partials"
import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core"
import { CategoryStyles } from "../styles/CategoryStyles"
import { ConsumableData } from "../components/ConsumableData"

type Props = {
  classes: {
    image: string
    card: string
  }
}
const Consumables = ({ classes }: Props) => {
  const consumables = ConsumableData();

  return (
    <Layout
      HeaderComponent={
        <PageHeader id={"categoriesHeader"} header="Our Products" />
      }
      BreadComponent={
        <Breadcrumbs aria-label="breadcrumb">
          <BreadTypography>Products</BreadTypography>
        </Breadcrumbs>
      }
      hasFeatured={true}
    >
      <Container style={{ padding: 0, width: "100%" }}>
        <Grid
          container
          style={{ paddingTop: "10px", width: "auto", height: "100%" }}
          spacing={1}
          justify={"center"}
          xs={12}
          md={12}
        >
          {consumables.map(consumable => (
            <Grid item container justify="center" style={{ width: "auto" }}>
              <ConsumableCard consumable={consumable} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export default withStyles(CategoryStyles)(Consumables)
